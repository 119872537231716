import React, { useState } from "react";
import Select from "react-dropdown-select";
import { SubNodes } from "./node";
import RowErrorBoundary from "./error";
import Im from "immutable";
import { doFetch } from "storybook-dashboard/utils/fetching";
import { useReportContext } from "./context";

/* Reduce a path down to a map of all descendants.  Values of "false" will be rendered but hidden, however true will be fully rendered*/
const getShowPathDict = (path) => {
  if (!path || !path.length) {
    return {};
  }
  let depth = path.length / 7;
  let endPoints = Array(depth)
    .fill(1)
    .map((x, y) => 7 * (y + 1));
  let paths = endPoints.reduce((acc, cur) => ({ ...acc, [path.substring(0, cur)]: false }), {});
  paths = { ...paths, [path]: true };
  return paths;
};

function NoDataWarningRow(props) {
  return (
    <tr>
      <td colSpan={100}>No child nodes or path information to search</td>
    </tr>
  );
}

const getNodeIdFromPath = (commitNodes, path) => {
  return commitNodes.getIn([`byPath`, `${path}`])?.get(`uid`);
};

const postValue = ({ trackerId, commitId, scoreId, value }) => {
  console.log("Posting value to server", trackerId, commitId, scoreId, value);
  doFetch(`/api/tracker/${trackerId}/commit/${commitId}/score/${scoreId}/value/`, "POST", { value });
};

const getConversion = (data) =>
  Im.fromJS({
    fromPath: data.path,
    inputValue: data.value, //value of child
    convertedValue: data.value, //parent value after multiplied with conversion
    conversionFactor: 1,
    inputType: "select",
  });

const postChildValues = (props, value) => {
  let { rootPath: parentPath, commit, nodes, saveValues } = props;

  // Get the properties of the tracker/commit we are on
  let commitId = commit?.get("uid");
  let trackerId = commit?.get("tracker");
  console.log("postChildValues selecting a single child of parent node: ", parentPath, trackerId, commitId);

  // This is the path of the selected child
  let { path: selectedChildPath } = value;

  let childValues = nodes.map((node) => {
    let path = node.get("_path");
    return Im.Map({
      _path: path,
      value: selectedChildPath == path ? 1 : null,
    });
  });

  console.log("postChildValues setting child score values", childValues?.toJS());
  saveValues(childValues);
};

function SearchNodesRow(props) {
  let { rootPath: parentPath, indentLevel, nodes, show, commit, disableInputs } = props;

  let commitId = commit?.get("uid");
  let options = nodes
    .toList()
    .filter((node) => node && !node.getIn(["meta_json", "hidden"])) // Filter out nodes marked as "hidden"
    .map((node, i) => ({
      path: node.get("_path"),
      name: node.getIn(["metric", "name"]),
      key: i,
    }))
    .toJS();

  // Set the one to show as selected using a simple filter
  let values = options.filter((i) => show.get(i.path));

  return (
    <tr>
      <td colSpan={100}>
        <div style={{ marginLeft: `${(indentLevel + 1) * 1.5}em`, marginRight: "5em" }}>
          <Select
            disabled={disableInputs}
            options={options}
            values={values}
            labelField={"name"}
            valueField={"path"}
            clearable={true}
            searchable={true}
            searchBy={"name"}
            clearOnSelect={true}
            sortBy={"path"}
            placeholder={"Select an option"}
            onClearAll={() => {
              console.log("SearchNodesRow clearAll", commitId, parentPath);
              postChildValues(props, { path: undefined });
            }}
            onChange={(values) => {
              if (!values || !values[0]) {
                return null;
              }
              postChildValues(props, values[0]);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const getInitShow = (inputValueMap, nodes) => {
  return nodes.reduce((a, node) => {
    let path = node.get("_path");
    let metricId = node.getIn(["metric", "uid"]);
    return a.set(path, inputValueMap.get(metricId)?.get("value"));
  }, Im.Map());
};

export default function ChildSelectRow(props) {
  let { path, indentLevel, hide, disableInputs } = props;
  let { getNode, getDescendants, commit, inputValueMap, setMultipleInputValues } = useReportContext();
  let descendantNodes = getDescendants(getNode(path));
  let [show, setShow] = useState(getInitShow(inputValueMap, descendantNodes));

  if (hide) return null;

  if (!descendantNodes?.size) {
    return <NoDataWarningRow />;
  }

  const saveValues = (data) => {
    setMultipleInputValues(data);
  };

  return (
    <React.Fragment>
      <SearchNodesRow
        disableInputs={disableInputs}
        commit={commit}
        nodes={descendantNodes}
        indentLevel={indentLevel}
        rootPath={path}
        show={show}
        saveValues={saveValues}
      />
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={false} sortKey="metric.name" showOnly={{}} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}
