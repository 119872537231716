import React, { useState } from "react";
import Traec from "traec";

import BaseFormConnected from "traec-react/utils/form";
import { BSCard } from "traec-react/utils/bootstrap";

import { Spinner } from "traec-react/utils/entities";
import { projectDetailsFields } from "AppSrc/project/form";
import { isSuperuser } from "traec-react/utils";
import WorkPackageList from "AppSrc/project/wptree/wppanel";
import ReportingPeriodList from "AppSrc/project/reportPeriods/";
import { ApportionmentDetails } from "AppSrc/project/details/apportiomentDetails";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { SetMetaDataFields } from "AppSrc/forms/meta";
import { TechnicalDetails } from "AppSrc/project/wptree/details";
import { setAndShowModal } from "AppSrc/utils/modal";
import Octicon from "react-octicon";
import Swal from "sweetalert2";

import { useFullIds, useProjectPermissions } from "../utils/hooks";
import ProjectContextWrapper, { useProjectContext } from "../context";
import { ProjectPermission } from "../utils/permissions";
import { BreadCrumb } from "AppSrc/project/utils";

const _pushTemplate = ({ trackerId, excludes, keepTargets }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  let payload = {
    excludes: excludes.toList().toJS(),
    keep_targets: keepTargets,
  };
  console.log("Dispatching pushTemplate with excludes", excludes);

  let formData = new FormData();
  formData.append("type", "PUSH_TO_TEMPLATE_CHILDREN");
  formData.append("payload", JSON.stringify(payload));
  fetch.updateFetchParams({
    body: formData,
    postSuccessHook: () => {
      location.reload();
    },
  });
  fetch.dispatch();
};

const pushTemplate = ({ trackerId, excludes }) => {
  Swal.fire({
    title: "Re-sync with template?",
    //html: "Would you like to re-sync the metrics with this new template?",
    type: "success",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Re-sync with template",
    cancelButtonText: "Cancel",
    input: "checkbox",
    inputValue: 1,
    inputPlaceholder: "Do not sync template targets",
  }).then((result) => {
    if ("value" in result) {
      _pushTemplate({ trackerId, excludes, keepTargets: Boolean(result.value) });
    } else {
      console.log("Skipping re-sync step");
    }
  });
};

function ReportingPackageListRow({ row, excludes, setExcludes }) {
  let refId = row?.ref?.uid;
  let url = `/project/${row?.project?.uid?.substring(0, 8)}/wpack/${refId?.substring(0, 8)}/`;
  let excluded = excludes.has(refId);
  return (
    <tr>
      <td>{row?.project?.name}</td>
      <td>{row?.ref?.name}</td>
      <td style={{ textAlign: "center" }}>
        <a href={url} target="_blank">
          <Octicon name="link-external" />
        </a>
      </td>
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          className="form-control-sm"
          checked={excluded}
          onChange={() => setExcludes(excluded ? excludes.delete(refId) : excludes.add(refId))}
        />
      </td>
    </tr>
  );
}

function ReportingPackageList({ trackerId, data }) {
  let [excludes, setExcludes] = useState(Traec.Im.Set());

  let rows = data.map((row, i) => (
    <ReportingPackageListRow key={i} row={row} excludes={excludes} setExcludes={setExcludes} />
  ));

  return (
    <ErrorBoundary>
      <button
        className="btn btn-sm btn-outline-danger mb-2 float-right"
        onClick={() => pushTemplate({ trackerId, excludes })}
      >
        Re-sync all with this template
      </button>
      <div style={{ clear: "both" }} />
      <table width="100%" className="table table-sm">
        <tbody>
          <tr>
            <th>Project Name</th>
            <th>RP Name</th>
            <th style={{ textAlign: "center" }}>Url</th>
            <th style={{ textAlign: "center" }}>Exclude</th>
          </tr>
          {rows}
        </tbody>
      </table>
    </ErrorBoundary>
  );
}

const get_template_refs = ({ trackerId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  let formData = new FormData();
  formData.append("type", "REFS_FROM_THIS_TEMPLATE");
  fetch.updateFetchParams({
    body: formData,
    postSuccessHook: (data) => {
      console.log(data);
      setAndShowModal("TrackerTemplateModal", {
        title: `Reporting Packages using this template`,
        body: <ReportingPackageList trackerId={trackerId} data={data?.payload || []} />,
      });
    },
  });
  fetch.dispatch();
};

function TemplateChildrenList({ tracker }) {
  let trackerId = tracker?.get("uid");

  return (
    <ErrorBoundary>
      <button className="btn btn-sm btn-outline-secondary mr-2" onClick={() => get_template_refs({ trackerId })}>
        Reporting Packages that use this template
      </button>
    </ErrorBoundary>
  );
}

function TemplateForm(props) {
  let { isTemplate, isPublic, toggleTemplateState, setTrackerTemplate, user } = props;
  // Only render the is_public checkbox if we have set the template
  let isPublicCheckbox =
    !isTemplate | !isSuperuser(user) ? null : (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isPublic}
          name="isPublic"
          id="isPublic"
          onChange={toggleTemplateState}
        />
        <label className="form-check-label" htmlFor="isPublic">
          Template is Public?
        </label>
      </div>
    );
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="isTemplate"
            checked={isTemplate}
            id="isTemplate"
            onChange={toggleTemplateState}
          />
          <label className="form-check-label" htmlFor="isTemplate">
            Set as Template?
          </label>
        </div>
        {isPublicCheckbox}
        <br />
        <button className="btn btn-sm btn-primary mr-2" onClick={setTrackerTemplate}>
          Set
        </button>
        <TemplateChildrenList {...props} />
      </div>
    </div>
  );
}

function TemplatePanel(props) {
  let { isRootRef } = props;
  if (!isRootRef) {
    return null;
  }
  return (
    <div className="row">
      <BSCard widthOffset="col-sm-12" title="Template settings" body={<TemplateForm {...props} />} />
    </div>
  );
}

const ProjectDetails = (props) => {
  const { projectId, trackerId, isRootRef } = useFullIds();
  const { commit, project, rootRef } = useProjectContext();
  const tracker = project.get("trackers").first();

  const setInitFields = () => {
    let setFields = {};
    Object.assign(setFields, projectDetailsFields);
    Object.keys(setFields).map((key) => {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        setFields[key].value = project.getIn(["meta_json", metaKey]) || setFields[key].value;
      } else {
        setFields[key].value = project.get(key) || setFields[key].value;
      }
    });
    return setFields;
  };

  const setPostData = (post) => {
    // Get the fields that are prefixed with meta_ and put them into the meta_json object
    let postData = {};
    let metaData = {};
    for (let [key, value] of Object.entries(post)) {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        Object.assign(metaData, { [metaKey]: value });
      } else {
        Object.assign(postData, { [key]: value });
      }
    }
    Object.assign(postData, { meta_json: metaData });
    return postData;
  };

  const setTrackerTemplate = (e) => {
    e.preventDefault();
    let { trackerId } = this.props;
    if (!trackerId) {
      return null;
    }

    let fetch = new Traec.Fetch("tracker", "patch", { trackerId });
    fetch.updateFetchParams({
      body: {
        is_template: this.state.isTemplate,
        is_public: this.state.isPublic,
      },
    });
    fetch.dispatch();
  };

  if (!project) {
    return <Spinner title="Loading..." explanation="" />;
  }

  const fetch = new Traec.Fetch("project", "put", { projectId });
  const formParams = fetch.params;
  const isTemplate = tracker.get("is_template");
  const isPublic = tracker.get("is_public");

  console.log("AAAAAAAAAAAAAAAAAAA", projectId, trackerId, project?.toJS());

  return (
    <ProjectPermission projectId={projectId} requiresAdmin={true}>
      <h3>{isRootRef ? "Project Settings" : "Reporting Package Settings"}</h3>
      <BreadCrumb />

      <div className="row">
        <ErrorBoundary>
          <BSCard
            widthOffset="col-sm-12"
            title="Project details"
            body={
              <BaseFormConnected
                params={formParams}
                fields={setInitFields()}
                prePostHook={setPostData}
                forceShowForm={true}
                hideUnderline={true}
              />
            }
          />
        </ErrorBoundary>
      </div>

      <ErrorBoundary>
        <div className="row">
          <BSCard
            widthOffset="col-sm-12"
            title="Project meta-data"
            body={
              <SetMetaDataFields
                saveMetaFetchProps={{
                  handler: "project",
                  method: "patch",
                  params: { projectId },
                }}
                pushMetaFetchProps={{
                  handler: "tracker_dispatch",
                  method: "post",
                  params: { trackerId },
                }}
                metaJson={project.get("meta_json")}
              />
            }
          />
        </div>
      </ErrorBoundary>

      <div className="row">
        <ErrorBoundary>
          <WorkPackageList showMenu={true} />
        </ErrorBoundary>
      </div>

      <ErrorBoundary>
        <TemplatePanel
          {...props}
          isTemplate={isTemplate}
          isPublic={isPublic}
          toggleTemplateState={(e) => {}}
          setTrackerTemplate={setTrackerTemplate}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <ApportionmentDetails project={project} />
      </ErrorBoundary>

      <div className="row">
        <ErrorBoundary>
          <ReportingPeriodList projectId={projectId} />
        </ErrorBoundary>
      </div>

      <div className="row">
        <ErrorBoundary>
          <TechnicalDetails {...props} />
        </ErrorBoundary>
      </div>
    </ProjectPermission>
  );
};

export default (props) => {
  return (
    <ProjectContextWrapper>
      <ProjectDetails {...props} />
    </ProjectContextWrapper>
  );
};
