import React, { useState } from "react";
import Octicon from "react-octicon";

import ErrorBoundary from "./error";
import { SubNodes } from "./node";

import { isRequiredOnFreq, NotRequiredMsg, ReportPeriodText } from "./reportMetricRow";
import { NotRequiredAlert, FrequencyAlert } from "./index";

function CategoryTitleRow({ tree, collapsed, hide }) {
  if (hide) {
    return null;
  }
  let treeId = tree.uid;
  let treeName = tree.name;
  return (
    <tr>
      <td colSpan="9" className="border-0">
        <a data-toggle="collapse" href={`#${treeId}`} aria-expanded={"false"}>
          <Octicon className="expand_caret m-0 p-0" name={collapsed ? "triangle-right" : "triangle-down"} />
          <b>{treeName}</b>
        </a>
      </td>
    </tr>
  );
}

export default function ReportCategoryItem(props) {
  let { tabDetails, hideTitleRow, currentReportingPeriod, disableInputs } = props;

  // If this tab has a reporting frequency then
  let freqDetails = isRequiredOnFreq(tabDetails.meta_json, currentReportingPeriod);
  let notRequiredThisPeriod = (freqDetails || {}).dueThisReport === false;

  if (notRequiredThisPeriod) {
    return (
      <ErrorBoundary>
        <CategoryTitleRow tree={tabDetails} hide={hideTitleRow} collapsed={false} />
        <tr>
          <td colSpan={100}>
            <NotRequiredAlert {...freqDetails} />
          </td>
        </tr>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <CategoryTitleRow tree={tabDetails} hide={hideTitleRow} collapsed={false} />
      <ErrorBoundary>
        <SubNodes
          {...props}
          indentLevel={hideTitleRow ? -1 : 0}
          disableInputs={disableInputs || tabDetails.isSectionComplete}
        />
      </ErrorBoundary>
      <tr />
    </ErrorBoundary>
  );
}
