import React from "react";
import { SubNodes } from "./node";
import Im from "immutable";

export default function ReportTreeRow(props) {
  let { cref } = props;
  let commit = cref?.get("latest_commit");
  let sortKey = commit?.get("meta_json.sortKey") || "metric.name";
  sortKey = sortKey == "name" ? "metric.name" : sortKey;

  return (
    <React.Fragment>
      {/*<tr>
        <td colSpan="100%">{tree ? tree.get("name") : "undefined"}</td>
      </tr>*/}
      <SubNodes {...props} sortKey={sortKey} />
    </React.Fragment>
  );
}
