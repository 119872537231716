import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";

export const useProjectPermission = ({ projectId }) => {
  let { data } = useApi("/api/project/{projectId}/permission/", { projectId });

  let projectDisciplines = data?.get("project_disciplines") || Im.List();
  let baseDisciplines = Im.Set(projectDisciplines?.map((i) => i.get("base_uid")));
  let isAdmin = data?.get("is_admin");

  return {
    permissions: data,
    isAdmin,
    baseDisciplines,
  };
};

export const useCompanyPermission = ({ companyId }) => {
  let { data } = useApi("/api/company/{companyId}/permission/", { companyId });

  let isAdmin = data?.get("is_admin");

  return {
    permissions: data,
    isAdmin,
  };
};

export const usePermission = ({ projectId, companyId }) => {
  return projectId ? useProjectPermission({ projectId }) : useCompanyPermission({ companyId });
};
