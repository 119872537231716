import React, { useState } from "react";
import { ApproveForm, getCommitStatus } from "AppSrc/project/commits/row";
import { BSBtn } from "traec-react/utils/bootstrap";
import { Redirect } from "react-router-dom";
import { useProjectPermissions } from "../utils/hooks";

export function ApproveRejectForm(props) {
  let { projectId, isApprover } = useProjectPermissions();
  let [showForm, setShowForm] = useState(false);
  let [status, setStatus] = useState(null);
  let [comment, setComment] = useState("");
  let { commit, crefId, currentPeriodString } = props;
  let commitStatus = getCommitStatus(commit);

  //console.log(this.state.showForm, commitStatus === "Pending Approval");
  if (isApprover(commit)) {
    if (showForm && !(commitStatus === "Pending Approval")) {
      return <Redirect to={`/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/evals`} />;
    }

    if (showForm && commitStatus === "Pending Approval") {
      return <ApproveForm {...props} />;
    }

    if (!showForm && commitStatus === "Pending Approval") {
      return <ApproveRejectButton {...props} />;
    }
  }
  return <DisplayStatus {...{ currentPeriodString, status: commitStatus }} />;
}

export function ApproveRejectButton(props) {
  return (
    <React.Fragment>
      <div className="m-2">
        <BSBtn
          text={"Approve report"}
          extra_className="ml-3"
          onClick={(e) => this.setState({ status: "APPROVE", showForm: true })}
        />
      </div>
      <div className="m-2">
        <BSBtn text={"Reject report"} onClick={(e) => this.setState({ status: "REJECT", showForm: true })} />
      </div>
    </React.Fragment>
  );
}

export function DisplayStatus({ currentPeriodString, status }) {
  return (
    <div>
      <p>
        Reporting period <b>{currentPeriodString}</b> is <b>{status}</b>
      </p>
    </div>
  );
}
