import Papa from "papaparse";
import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors";
import Swal from "sweetalert2";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Im, { merge } from "immutable";
import { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { mergeNodes } from "../../metrics/node";

export const HeaderButtons = (props) => {
  let { setData, data, readOnly, formTemplate } = props;

  if (readOnly) {
    return null;
  }

  let templateLink = formTemplate?.get("url");
  let templateName = formTemplate?.get("name");

  return (
    <ErrorBoundary>
      <Explanation show={templateLink} />
      <DownloadTemplateButton templateLink={templateLink} templateName={templateName} />
      <UploadCSVButton setData={setData} data={data} />
      <ClearButton setData={setData} />
      <input style={{ display: "none" }} type="file" id="file-selector" />
      <SaveButton {...props} />
    </ErrorBoundary>
  );
};

const DownloadTemplateButton = (props) => {
  let { templateLink, templateName } = props;

  if (!templateLink) {
    return null;
  }

  return (
    <a className="btn btn-sm btn-warning pt-0 pb-0 ml-1 mr-1 mb-2" href={templateLink} download={templateName}>
      Download Template
    </a>
  );
};

const ClearButton = (props) => {
  let { setData } = props;

  return (
    <button className="btn btn-sm btn-danger pt-0 pb-0 ml-1 mr-1 mb-2" onClick={(e) => setData(Im.List())}>
      Clear all data
    </button>
  );
};

const UploadCSVButton = (props) => {
  let { setData, data } = props;

  return (
    <>
      <button
        className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2"
        onClick={(e) => {
          e.preventDefault();
          $("#file-selector").on("change", (e) => {
            console.log("Selected files", e.target.files);
            console.log("Parsing CSV file");
            Papa.parse(e.target.files[0], {
              skipEmptyLines: "greedy",
              complete: (results) => {
                let headers = results.data[0];
                let newData = results.data
                  .slice(1)
                  .map((row) => headers.reduce((acc, cur, i) => Object.assign(acc, { [cur]: row[i] }), {}));
                setData(data.concat(Im.fromJS(newData)));
              },
            });
          });
          $("#file-selector").trigger("click");
        }}
      >
        Upload from CSV
      </button>
    </>
  );
};

const Explanation = (props) => {
  let { show } = props;

  if (!show) {
    return (
      <>
        <p>
          Either complete the table below manually by adding a new row for each employee or follow the steps below to
          upload your data from a CSV file:
        </p>
        <ul>
          <li>
            <b>Step 1</b>: Click the "Upload from CSV" button and upload the CSV
          </li>
          <li>
            <b>Step 2</b>: Press the "Save" button to save the data
          </li>
        </ul>
        <p>
          Please ensure that the CSV file upload has the heading columns that match the table headers below.
          <br />
          Row reference populates automatically from the upload CSV and will help you identify errors within specific
          rows of data.
        </p>
        <p>
          For manual entry, please add all information needed and don't forget to click “Add” for each data entry row
          before you click “Save”.
        </p>
      </>
    );
  }

  return (
    <>
      <p>
        Either complete the table below manually by adding a new row for each employee or follow the steps below to
        upload your data from a CSV file:
      </p>
      <ul>
        <li>
          <b>Step 1</b>: Download the Excel template
        </li>
        <li>
          <b>Step 2</b>: Save the completed Excel templates as a .CSV file
        </li>
        <li>
          <b>Step 3</b>: Click the "Upload from CSV" button and upload the CSV
        </li>
        <li>
          <b>Step 4</b>: Press the "Save" button to save the data
        </li>
      </ul>
      <p>
        Please ensure that the CSV file upload has the heading columns that match the table headers below.
        <br />
        Row reference populates automatically from the upload CSV and will help you identify errors within specific rows
        of data.
      </p>
      <p>
        For manual entry, please add all information needed and don't forget to click “Add” for each data entry row
        before you click “Save”.
      </p>
    </>
  );
};

const SaveButton = (props) => {
  let { fields, data, path, filename, assignments, closeModal } = props;
  const [pending, setPending] = useState(false);

  return (
    <button
      className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2 float-right"
      onClick={(e) => {
        e.preventDefault();
        if (pending) return null;
        let text = dataToCSVText({ fields, data });
        uploadFile(textToFormData({ text, path, filename }), {
          ...props,
          setPending,
          saveSuccessHandler: assignments ? saveSuccessHandler : closeModal,
        });
      }}
    >
      {pending ? <div className="spinner-border spinner-border-sm" /> : "Save"}
    </button>
  );
};

const dataToCSVText = ({ fields, data }) => {
  let headers = fields.map((field) => field.get("header"));
  let lines = [];
  lines.push(headers.join(","));
  data.map((row) => {
    lines.push(headers.map((header) => row.get(header) || "").join(","));
  });
  return lines.join("\n");
};

const textToFormData = ({ text, path, filename = "data.csv" }) => {
  let formData = new FormData();
  const blob = new Blob([text], { type: "text/csv" });
  formData.append("fileobj", blob, filename);
  formData.append("type", "document");
  formData.append("path", path);
  console.log("Packaged csv text as blob in multipart file object", text);
  return formData;
};

const uploadFile = (formData, props) => {
  /* You can send this as a raw file if you set:
    headers: { "content-type": null: "content-disposition": "attachment; filename=upload.jpg" },
    body: this.state.selectedFiles[0]
    */
  let { trackerId, commitId, path, setPending, saveSuccessHandler, fetches } = props;

  if (setPending) setPending(true);
  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PUT", formData).then(({ payload }) => {
    let { data, mutate } = fetches.nodes;
    mutate(mergeNodes(data, payload));
    //if (setPending) setPending(false);
    if (saveSuccessHandler) {
      let currentDocObject = payload
        .filter((i) => i.get("type") == "documentstatus")
        .first()
        ?.getIn(["node", "documentstatus", "current_object"]);
      console.log("Running saveSucessHandler", currentDocObject?.toJS(), props);
      saveSuccessHandler({
        ...props,
        currentDocObject,
      });
    }
  });
};

const saveSuccessHandler = (props) => {
  Swal.fire({
    title: "Form data saved",
    text: "Your form data has been saved successfully.  Do you wish to auto-complete the report from the saved data?",
    type: "success",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Auto-fill report from data provided",
    cancelButtonText: "No, continue to edit data table",
  }).then((result) => {
    if (result.value) {
      fetchAssignToMetricValues(props);
    } else {
      console.log("Not doing auto-assignment.");
    }
  });
};

const fetchAssignToMetricValues = ({ trackerId, commitId, doc, currentDocObject }) => {
  doFetch(`/api/tracker/${trackerId}/dispatch/`, "POST", {
    type: "CSV_TO_INPUT_VALUES",
    payload: {
      commitId,
      fileId: currentDocObject?.get("uid"),
      docId: doc?.get("uid"),
    },
  }).then((e) => {
    alertSuccess({
      title: "Report auto-filled",
      text: "Your report has been auto-completed based on the spreadsheet data provided. The browser will now refresh.",
      onConfirm: () => {
        window.location.reload();
      },
    });
  });
};
