import Im from "traec/immutable";
import React, { useState } from "react";
import Traec from "traec";
import Octicon from "react-octicon";

import ProjectContextWrapper, { useProjectContext } from "../context";
import { useFullIds, useProjectPermissions } from "../utils/hooks";
import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import CommitTableRow from "./row";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/btnDropdown";

const sortFuncs = {
  reportingPeriod: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("reporting_period_data")?.get("startDate");
    const itemB = b?.get("reporting_period_data")?.get("startDate");

    if (new Date(itemA) < new Date(itemB)) {
      return -dir;
    } else if (new Date(itemA) > new Date(itemB)) {
      return dir;
    }

    return 0;
  },
  lastUpdated: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("meta_json")?.get("lastUpdateOn");
    const itemB = b?.get("meta_json")?.get("lastUpdateOn");

    if (new Date(itemA) < new Date(itemB)) {
      return -dir;
    } else if (new Date(itemA) > new Date(itemB)) {
      return dir;
    }

    return 0;
  },
  status: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("meta_json")?.get("status")?.toLowerCase();
    const itemB = b?.get("meta_json")?.get("status")?.toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
  lastUpdateBy: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = (
      a?.get("meta_json")?.get("lastUpdateBy")?.get("first_name") +
      " " +
      a?.get("meta_json")?.get("lastUpdateBy")?.get("last_name")
    ).toLowerCase();
    const itemB = (
      b?.get("meta_json")?.get("lastUpdateBy")?.get("first_name") +
      " " +
      b?.get("meta_json")?.get("lastUpdateBy")?.get("last_name")
    ).toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
  comment: (sortDir) => (a, b) => {
    const dir = sortDir === "asc" ? 1 : -1;
    const itemA = a?.get("comment")?.toLowerCase();
    const itemB = b?.get("comment")?.toLowerCase();

    if (itemA < itemB) {
      return -dir;
    } else if (itemA > itemB) {
      return dir;
    }

    return 0;
  },
};

function CommitTableRows(props) {
  let { sortBy, sortDir } = props;
  let { getHistory, projectDisciplines, project, reportingPeriods } = useProjectContext();
  const { data: userPermissions } = useProjectPermissions();
  let { data: commits, isLoading } = getHistory();
  let { projectId, crefId } = useFullIds();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Im.Map());

  if (isLoading) {
    return null;
  }

  commits = commits?.sortBy((commit) => commit.get("created")).reverse() || Traec.Im.List();
  return [...commits]
    .sort(sortBy ? sortFuncs[sortBy](sortDir) : undefined)
    .map((commit, i) => (
      <CommitTableRow
        key={i}
        commit={commit}
        commits={commits}
        {...props}
        userPermissions={userPermissions}
        projectId={projectId}
        projectBaseDisciplineMap={projectBaseDisciplineMap}
        crefId={crefId}
        projectDisciplines={projectDisciplines}
        project={project}
        projectReportingPeriods={reportingPeriods}
      />
    ));
}

export default function WPCommits(props) {
  const [state, setState] = useState({
    sortBy: null,
    sortDir: "asc",
    hideNullReportingPeriod: true,
    fetchedLog: false,
    fetchedUrls: {},
    fetchedReportingPeriods: false,
    fetchedMembers: false,
    nameFormParams: {
      stateParams: {},
      fetchParams: {},
      initFields: {},
    },
  });

  const { company, project, cref, isRootRef } = props;

  let { hideNullReportingPeriod: hideNRP } = state;

  const titles = [
    { key: "reportingPeriod", title: "Reporting Period" },
    { key: "status", title: "Status" },
    { key: "lastUpdated", title: "Last Updated" },
    { key: "lastUpdateBy", title: "Last Update By" },
    { key: "comment", title: "Comment" },
    { key: "", title: "" },
  ]; //"Supplier(s)" used to be first column

  const headCols = titles.map((title, i) => (
    <th
      key={i}
      scope="col"
      style={{ cursor: "pointer" }}
      onClick={() => {
        const { sortDir } = state;
        if (sortDir === "asc") {
          setState({ ...state, sortBy: title.key, sortDir: "desc" });
        } else {
          setState({ ...state, sortBy: title.key, sortDir: "asc" });
        }
      }}
    >
      {title.title}{" "}
      {title.key === state.sortBy ? (
        <Octicon name={state.sortDir === "asc" ? "chevron-down" : "chevron-up"} />
      ) : (
        <Octicon name="chevron-up" style={{ opacity: 0.5 }} />
      )}
    </th>
  ));

  return (
    <ProjectContextWrapper>
      <h3>Reports Submitted</h3>
      <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />
      <BSBtnDropdown
        links={[
          {
            name: `${hideNRP ? "Show" : "Hide"} null Reporting Periods`,
            onClick: () => {
              setState({ hideNullReportingPeriod: !hideNRP });
            },
          },
        ]}
      />
      {/* Render the headers of the table */}
      <table className="table table-sm">
        <thead>
          <tr>{headCols}</tr>
        </thead>
        {/* Render the rows */}
        <tbody>
          <CommitTableRows {...props} sortBy={state.sortBy} sortDir={state.sortDir} hideNullReportingPeriod={hideNRP} />
        </tbody>
      </table>
    </ProjectContextWrapper>
  );
}
