import React from "react";
import Traec from "traec";

import { BSCard, BSBtn, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import { workPackageFields } from "./form";
import WPTree from "./index";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { getTerm } from "AppSrc/tree/utils";
import { useFullIds } from "../utils/hooks";
import { useProjectContext } from "../context";
import useApi from "storybook-dashboard/utils/fetching";

/* A component that gives the work packages of a work package
with a form to create more work packages as requried.
*/

const getFetch = (props) => {
  let { projectId, trackerId, refId, rootCommitId, commitId, rootTreeId: treeId } = props;
  let fromCommitId = commitId || rootCommitId;

  let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
    trackerId,
    refId,
    commitId,
    treeId,
    skip_categories: true,
  });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      let _body = {
        ...body,
        ref_name: body.name || "master",
        from_commit: fromCommitId,
      };
      console.log("CREATING REF WITH PARAMETERS", _body);
      return _body;
    },
    postSuccessHook: () => {
      $(`#${projectId}`).modal("hide");
    },
  });

  return fetch;
};

export default function WorkPackageList(props) {
  let { showMenu, tenant_meta } = props;

  // Get the root ref from the tracker
  const { projectId, trackerId, refId, commitId } = useFullIds();
  const { commit } = useProjectContext();

  let { data: tracker } = useApi("/api/tracker/{trackerId}/", { trackerId });
  let rootMasterRefId = tracker.getIn(["root_master", "uid"]);

  // Get the root tree from the cref
  let treeId = commit?.getIn(["tree_root", "uid"]);

  let fetch = getFetch({ ...props, trackerId, refId, commitId, treeId });
  let rp_term = getTerm("Reporting Package", props);

  return (
    <ErrorBoundary>
      <BSCard
        widthOffset="col-sm-12"
        title={`${rp_term}s in Project`}
        button={
          <BSBtn
            onClick={() => {
              $(`#${projectId}`).modal("show");
            }}
            text={`Add a ${rp_term}`}
          />
        }
        body={
          <WPTree
            rootRef={tracker.get("root_master")}
            rootMasterRefId={rootMasterRefId}
            showMenu={showMenu}
            tenant_meta={tenant_meta}
          />
        }
        form={
          <BSModal
            id={projectId}
            title={`Add a ${rp_term}`}
            body={
              <BaseFormConnected
                params={fetch.params}
                fields={workPackageFields}
                forceShowForm={true}
                hideUnderline={true}
              />
            }
          />
        }
      />
    </ErrorBoundary>
  );
}
