import React, { useContext } from "react";
import { Spinner } from "traec-react/utils/entities";
import useApi, { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { useFullIds } from "./utils/hooks";
import Im from "immutable";

const ProjectContext = React.createContext();

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

const getNestedPath = (path, includeRoot = false) => {
  let parts = path.match(/.{1,7}/g);
  if (!includeRoot) {
    parts = parts.slice(1);
  }
  return parts.join("._children.").split(".");
};

const flattenNodeAndMerge = (existing, node) => {
  let type = node.get("type");
  let data = node.getIn(["node", type]).set("_type", type).set("_path", node.get("path"));
  return data.merge(existing);
};

export const isMissing = (data) => Object.values(data).some((i) => !i);

const getParentPath = (path) => path.slice(0, -7);

// Get deeply nested representation of the report data structure
const getDeepNestedNodesByPath = (nodesByPath) =>
  nodesByPath
    ?.reduce(
      (a, c) =>
        a.updateIn(getNestedPath(c.get("path"), true), Im.Map(), (existing) => flattenNodeAndMerge(existing, c)),
      Im.Map()
    )
    ?.first()
    ?.get("_children");

export const isLoading = (fetches) => Object.values(fetches).some((i) => i.isLoading);

export default function ProjectContextWrapper({ children }) {
  let { projectId, trackerId, commitId, refId } = useFullIds();

  // Independent of the report
  let reportingPeriods = useApi("/api/project/{projectId}/reporting_periods/", { projectId });

  // Conversion factors are mixed with global instances and ones local to the
  // report, these don't change during the life time of the report
  let conversionFactors = useApi("/api/tracker/{trackerId}/commit/{commitId}/convfactor/", { trackerId, commitId });

  // These don't change, a new report is created when this one is submitted
  // The definition of the reporting package metadata and the container for the report

  let commit = useApi("/api/tracker/{trackerId}/ref/{refId}/commit/{commitId}/", { trackerId, refId, commitId });
  // The tree of fields in the reporting package
  let nodes = useApi("/api/tracker/{trackerId}/commit/{commitId}/node/", { trackerId, commitId });

  let projectDisciplines = useApi("/api/project/{projectId}/discipline/", { projectId });
  let project = useApi("/api/project/{projectId}/", { projectId });

  // Get a mapping of the data from the required fetches
  let fetches = { reportingPeriods, commit, nodes, conversionFactors, project, projectDisciplines };

  // Show a loading message while getting data
  if (isLoading(fetches)) {
    return (
      <Spinner title="Loading..." explanation={"Loading report..."} timedOutComment={"Loading report timed out"} />
    );
  }

  let data = Object.keys(fetches).reduce((a, key) => {
    a[key] = fetches[key].data;
    return a;
  }, {});

  if (isMissing(data)) {
    return (
      <Spinner
        title="Error loading"
        explanation={"Error loading report"}
        timedOutComment={"Error loading report timed out"}
      />
    );
  }

  // Get only the nodes we need to render the report
  let includeTypes = Im.Set([
    "tree",
    "metricscore",
    "document",
    "trackerdescription",
    "trackercomment",
    "documentstatus",
  ]);
  let filteredNodes = data.nodes?.filter((i) => includeTypes.has(i.get("type")));

  // A simple mapping of nodes by path with children paths nested
  let nodesByPath = filteredNodes?.reduce(
    (a, c) =>
      a
        .update(c.get("path"), Im.Map(), (existing) => flattenNodeAndMerge(existing, c))
        .updateIn([getParentPath(c.get("path")), "_children"], Im.Set(), (existing) => existing.add(c.get("path"))),
    Im.Map()
  );

  // Helper function to get child nodes of a node
  const getChildren = (node) => node.get("_children", Im.Set()).map((path) => nodesByPath.get(path));

  const getDescendants = (node) =>
    (nodesByPath?.toList() || Im.List())?.filter((item) => {
      let rootPath = node.get("_path");
      let itemPath = item.get("_path");
      return itemPath?.startsWith(rootPath) && itemPath !== rootPath;
    });

  // Get a mapping of the conversion factors (from metricId to unit)
  let conversionFactorMap = data.conversionFactors.reduce(
    (a, cf) => a.set(Im.fromJS([cf.getIn(["metric", "uid"]), cf.get("toUnit")]), cf),
    Im.Map()
  );

  const getHistory = () => {
    return useApi(`/api/tracker/${trackerId}/ref/${refId}/commit/`);
  };

  const setCommitMeta = async (meta_json) => {
    let { mutate } = fetches.commit;
    console.warn("Saving commit meta", commitId, meta_json);
    fetcher(`/api/tracker/${trackerId}/ref/${refId}/commit/${commitId}/`, "PATCH", { meta_json }).then(({ payload }) =>
      mutate(payload)
    );
  };

  return (
    <ProjectContext.Provider
      value={{
        ...data,
        fetches,
        nodesByPath,
        conversionFactorMap,
        getNestedPath,
        getParentPath,
        getChildren,
        getDescendants,
        getNode: (path) => nodesByPath.get(path),
        rootNode: getChildren(nodesByPath.get("")).first(),
        getDeepNestedNodesByPath,
        getHistory,
        setCommitMeta,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
