import React from "react";
import CategoryRow from "./categoryRow";
import { useProjectContext } from "AppSrc/project/context";

export default function MetricCategories(props) {
  let { rootNode, getChildren } = useProjectContext();

  const categories = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree")
    .map((category, i) => <CategoryRow key={i} path={category.get("_path")} />);

  return <div className="container-fluid mt-3 mr-0 pr-0">{categories}</div>;
}
