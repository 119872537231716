import React from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "traec-react/errors";
import SystemDownPage from "storybook-dashboard/backend_health";

import Home from "AppSrc/home";
import LoginRedirect from "./login-redirect";
import AdminPage from "AppSrc/admin";
import GeoReport from "AppSrc/report/geo";
import SBCCReport from "AppSrc/report/sbcc";
import CompanyProjectPage from "AppSrc/tree/mainPage";

import TestNewDashboards from "./testNewDashboards";

function jiraBugCollectorFieldValues() {
  //console.log("Getting field values for support form submission");
  var values = {
    //fullname: "User name",
    //email: "user@my.domain",
    recordWebInfo: "1", // field Name
    recordWebInfoConsent: ["1"], // field Id
  };
  return values;
}

window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: function (showCollectorDialog) {
    jQuery("#jira-bug-collector").click(function (e) {
      e.preventDefault();
      showCollectorDialog();
    });
  },
  fieldValues: jiraBugCollectorFieldValues,
};

function MainSwitch(props) {
  return (
    <Switch>
      {/* Route to a Company Dashboard */}
      <Route path="/company/:_companyId" component={CompanyProjectPage} />

      {/* Route to a Project or WorkPackage Dashboard */}
      <Route path="/project/:_projectId/wpack/:_refId" component={CompanyProjectPage} />
      <Route path="/project/:_projectId" component={CompanyProjectPage} />

      {/* Router to the admin path */}
      <Route path="/tenant/admin/" component={AdminPage} />

      {/* Pages for reporting */}
      <Route exact path={`/report/geo`} component={GeoReport} />
      <Route exact path={`/report/sbcc/:_type/:_typeId`} component={SBCCReport} />

      {/* Pages for reporting */}
      <Route exact path={`/dashboard_test`} component={TestNewDashboards} />

      {/* Default render homepage if no path matched */}
      <Route component={Home} />
    </Switch>
  );
}

export function AppRouter(props) {
  return (
    <ErrorBoundary title="Error this page">
      <LoginRedirect>
        <SystemDownPage>
          <MainSwitch />
        </SystemDownPage>
      </LoginRedirect>
    </ErrorBoundary>
  );
}
