import React from "react";
import Im from "immutable";
import DropdownLogin from "./loginDropdown";
import { MenuItem } from "./item";
import { DropDownItem } from "./dropdown";
import { isSuperuser } from "storybook-dashboard/utils";
import { useToken } from "storybook-dashboard/auth/token";

import { Route, Switch } from "react-router-dom";
import { useFullIds } from "../project/utils/hooks";
import { NavBarItems as ProjectItems } from "../project/navbar";
import { NavBarItems as CompanyItems } from "../company/navbar";

export default function NavBarRouter(props) {
  const render = () => <NavBar {...props} />;
  return (
    <Switch>
      <Route path="/company/:_companyId" render={render} />
      <Route path="/project/:_projectId/wpack/:_refId" render={render} />
      <Route path="/project/:_projectId" render={render} />
      <Route render={render} />
    </Switch>
  );
}

export function NavBar(props) {
  let {
    user,
    brand,
    extraClass,
    preUserItems,
    include_myprofile,
    includeUser,
    userLabel,
    getUserItemLabel,
    createText,
    azureConfig,
  } = props;

  let { isAuthenticated } = useToken();

  return (
    <div className="navbar-area">
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light navbar-static-top ${extraClass}`}
        role="navigation"
      >
        <div className="container-fluid m-0 p-0">
          <div className="navbar-brand">
            <NavBrand brand={brand} />
          </div>

          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#exCollapsingNavbar"
          >
            &#9776;
          </button>

          <div className="collapse navbar-collapse" id="exCollapsingNavbar">
            <ul className="nav navbar-nav flex-row justify-content-between ml-auto">
              <NavBarItems />
              {preUserItems}
              <DropdownLogin
                include_myprofile={include_myprofile}
                includeUser={includeUser}
                userLabel={userLabel}
                getUserItemLabel={getUserItemLabel}
                createText={createText}
                azureConfig={azureConfig}
                user={user}
                isAuthenticated={isAuthenticated}
              />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

function NavBarItems() {
  let { projectId } = useFullIds();
  return projectId ? <ProjectItems /> : <CompanyItems />;
}

export const NavItems = ({ items, user, permission }) => {
  if (items == null) return null;
  return items.map((item, i) => <NavItem key={i} item={item} user={user} permission={permission} />);
};

const NavBrand = ({ brand }) => {
  if (brand) return brand;
  return (
    <>
      Traec | <b>Procedural</b>.build
    </>
  );
};

const NavItem = (props) => {
  const { item, permission, user } = props;
  const label = item.get("label");
  const to = item.get("to");
  const requiresAdmin = item.get("requiresAdmin");

  if (requiresAdmin && !isSuperuser(user) && !permission?.get("is_admin")) {
    return null;
  }

  if (Im.List.isList(to)) {
    return <DropDownItem label={label} items={to} />;
  } else {
    return <MenuItem label={label} to={to} item={item} />;
  }
};
