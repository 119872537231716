import React from "react";
import useToken from "storybook-dashboard/auth/token";
import Im from "immutable";
import { usePermission } from "AppSrc/utils/permissions";

export const hasProjectPermission = (claims, permissions, requiresAdmin, requiredActions) => {
  // First check the user permissions for superuser status
  if (claims?.get("is_tenant_superuser")) {
    return true;
  }

  // Handle an admin-type user first
  let isAdmin = permissions?.get("is_admin");
  if (isAdmin) {
    return true;
  }
  if (requiresAdmin) {
    return false;
  }

  // Check against the list of allowed actions that the user has for this project
  let allowedActions = permissions?.get("actions") || Im.List();
  let requiredActionSet = Im.Set(requiredActions);
  let intersectActions = requiredActionSet.intersect(allowedActions);
  return intersectActions.size === requiredActionSet.size;
};

export function PermissionWarning(props) {
  return (
    <div className="alert alert-warning">
      <strong>PermissionDenied</strong> You do not have permission to view this content. Please contact the project
      admin to ensure that you are assigned an appropriate role.
    </div>
  );
}

export function PermissionObjectWarning() {
  return (
    <p>
      No user permissions found for this Project. Please contact the project admin to ensure you have permissions to
      view this Project
    </p>
  );
}

export const projectPermissionFilter = function (items, permissions, claims) {
  if (!permissions) {
    return null;
  }
  // Filter the items
  items = items.filter((i) => {
    if (i.requiresAdmin != null || i.requiredActions != null) {
      let requiresAdmin = i.requiresAdmin || false;
      let requiredActions = i.requiredActions || [];
      return hasProjectPermission(claims, permissions, requiresAdmin, requiredActions);
    }
    return true;
  });
  return items;
};

export function ProjectPermission({
  children,
  projectId,
  requiresAdmin,
  requiredActions = [],
  showWarning = false,
  warning,
  match,
}) {
  let { getClaims } = useToken();
  let claims = getClaims();
  let { permissions } = usePermission({ projectId });

  // setNavBar({ projectId, permissions, match, claims });
  if (!hasProjectPermission(claims, permissions || Im.Map(), requiresAdmin, requiredActions)) {
    return showWarning ? warning || <PermissionWarning /> : null;
  }

  return children;
}
