import React, { useState } from "react";
import { ErrorBoundary } from "traec-react/errors";
import Im from "immutable";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { AddRowForm, TableRow, TableHeaders } from "./tableRow";
import { dataToCSVText, textToFormData, uploadFile, saveSuccessHandler, HeaderButtons } from "./headerButtons";

export const DataTableVertical = (props) => {
  let { fields, formName, data, setData, trackerId, commitId, doc, path, readOnly, formTemplate, assignments } = props;

  let [addCaseStudies, setAddCaseStudies] = useState(true);

  let initNewData = fields.reduce((acc, cur) => acc.set(cur.get("header"), ""), Im.Map());
  let [newData, setNewData] = useState(initNewData);
  let [displaySaveBtn, setDisplaySaveBtn] = useState(false);

  let filename = `${formName}.csv`;

  console.log("data in DataTableVertical", data?.toJS());
  console.log("newData in DataTableVertical", newData?.toJS());

  let rows = data.map((d, i) => (
    <ErrorBoundary key={i}>
      <TableRow
        index={i}
        fields={fields}
        data={d}
        setData={setData}
        readOnly={readOnly}
        removeHandler={(e) => {
          confirmDelete({
            text: `Are you sure you want to remove this submission?`,
            onConfirm: () => {
              setData(data.delete(i));
              setDisplaySaveBtn(true);
            },
          });
        }}
        updateHandler={(rowData) => {
          setData(data.set(i, rowData));
        }}
        copyHandler={(rowData) => {
          setData(data.insert(i, rowData));
        }}
      />
    </ErrorBoundary>
  ));

  const postRowstoBackend = (fields, data) => {
    console.log("postingTobackEnd");
    if (typeof data === "undefined") return null;
    let text = dataToCSVText(fields, data);
    uploadFile(textToFormData({ text, path, filename }), {
      trackerId,
      commitId,
      doc,
      path,
      saveSuccessHandler: assignments ? saveSuccessHandler : null,
    });
  };

  return (
    <ErrorBoundary>
      <div className="container d-flex justify-content-center">
        <button
          onClick={() => {
            setAddCaseStudies(true);
          }}
          className="btn btn-primary mr-2"
          type="button"
          disabled={addCaseStudies ? true : false}
        >
          New
        </button>
        <button
          onClick={() => {
            setAddCaseStudies(false);
          }}
          className="btn btn-primary"
          type="button"
          disabled={!addCaseStudies ? true : false}
        >
          Completed ({rows.size})
        </button>
      </div>
      <div className="scrollTopContainer mt-2 p-0" style={{ overflowX: "auto" }}>
        {addCaseStudies ? (
          <div id="addCaseStudy">
            <h4 className="mt-3">New</h4>
            <p className="text-muted">
              Once added you can view & edit submissions in the <b>Completed</b> section
            </p>
            <table width="100%" className=" table table-hover border-0 scrollTopContent m-0 p-0">
              <tbody className="border-0">
                {readOnly ? null : (
                  <AddRowForm
                    readOnly={readOnly}
                    fields={fields}
                    values={newData}
                    doc={doc}
                    path={path}
                    trackerId={trackerId}
                    commitId={commitId}
                    filename={filename}
                    assignments={assignments}
                    data={data}
                    setData={setData}
                    setNewData={setNewData}
                    newData={newData}
                    initNewData={initNewData}
                    onChangeHandler={(e) => {
                      setNewData(newData.merge({ [e.target.name]: e.target.value }));
                    }}
                    addHandler={(e) => {
                      let newTable = data.unshift(newData);
                      setData(newTable);
                      postRowstoBackend(fields, newTable);
                      setNewData(initNewData);
                    }}
                  />
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div id="viewCaseStudies">
            <h4 className="mt-3">Completed: {rows.size}</h4>
            {rows.size > 0 || displaySaveBtn ? (
              <div className="my-2">
                <p className="text-muted">
                  Remember to <b>Save changes</b> after editing or deleting submissions
                </p>
                <HeaderButtons
                  filename={filename}
                  setData={setData}
                  data={data}
                  trackerId={trackerId}
                  commitId={commitId}
                  formTemplate={formTemplate}
                  fields={fields}
                  doc={doc}
                  path={path}
                  readOnly={readOnly}
                  assignments={assignments}
                />
              </div>
            ) : (
              <p className="text-muted">You have no completed submissions. Add a new submission to view it here.</p>
            )}
            <table width="100%" className=" table table-hover border-0 scrollTopContent mt-1 p-0">
              <tbody className="mt-2">{rows.size > 0 ? rows : null}</tbody>
            </table>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
