import React from "react";
import { Link } from "react-router-dom";
import { RightArrow } from "traec-react/utils/entities";
import useApi from "storybook-dashboard/utils/fetching";
import { useFullIds } from "./hooks";

function Company() {
  let { projectId } = useFullIds();
  let { data: project } = useApi("/api/project/{projectId}/", { projectId });

  let company = project?.get("company");
  if (!company) {
    return null;
  }

  let url = `/company/${company.get("uid")?.substring(0, 8)}`;
  let name = company.get("name");

  return <Link to={url}>{name}</Link>;
}

function Project() {
  let { projectId, isRootRef } = useFullIds();
  let { data: project } = useApi("/api/project/{projectId}/", { projectId });
  if (!project) {
    return null;
  }
  let url = projectId ? `/project/${projectId.substring(0, 8)}` : null;
  let name = project?.get("name");
  return (
    <React.Fragment>
      <RightArrow />
      &nbsp;&nbsp;
      {isRootRef ? name : <Link to={url}>{name}</Link>}
    </React.Fragment>
  );
}

function Report() {
  let { trackerId, refId, isRootRef } = useFullIds();
  let { data: ref } = useApi("/api/tracker/{trackerId}/ref/{refId}/", { trackerId, refId });
  if (isRootRef || !ref) {
    return null;
  }
  return (
    <React.Fragment>
      <RightArrow />
      &nbsp;&nbsp;
      {ref.get("name")}
    </React.Fragment>
  );
}

export function BreadCrumb() {
  return (
    <p>
      <Company />
      &nbsp;&nbsp;
      <Project />
      &nbsp;&nbsp;
      <Report />
    </p>
  );
}
