import React, { useState } from "react";
import Traec from "traec";
import { ReportingPeriodRangePicker } from "storybook-dashboard/dashboard/components/reportingPeriodRangePicker";
import DashboardComponents from "./dashboard";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/";
import { BreadCrumb } from "AppSrc/project/utils";
import { ProjectPermission } from "./utils/permissions";
import Moment from "moment";
import Im from "immutable";
import Swal from "sweetalert2";
import { fetchIndicatorExport } from "storybook-dashboard/dashboard/export/project/fetch";
import { useFullIds, useReportIndicators } from "./utils/hooks";

function IndicatorWarning({ indicators }) {
  if (indicators && indicators.size == 0) {
    return (
      <div className="alert alert-warning">
        <strong>No Indicators setup</strong>
        <br />
        This dashboard does not have any indicators setup yet. You will likely see minimal or no content on the
        dashboard. Request an administrator of this to setup some indicators to display the dashboard data.
      </div>
    );
  }
  return null;
}

export default function ProjectHome(props) {
  let { data: indicators } = useReportIndicators();
  let { projectId, refId, isRootRef } = useFullIds();

  let [filters, setFilters] = useState(Im.Map());
  let [dateRange, setDateRange] = useState({
    fromDate: new Moment().subtract(8, "months").startOf("day"),
    toDate: new Moment().endOf("day"),
  });

  const downloadExcel = (e, apiId, filename = "project_report.xlxs") => {
    e.preventDefault();
    const fromDate = dateRange.fromDate.format();
    const toDate = dateRange.toDate.format();
    let fetch = new Traec.Fetch(apiId, "list", {
      projectId,
      refId,
      fromDate,
      toDate,
      format: "excel",
    });
    Swal.queue([
      {
        title: "Downloading",
        confirmButtonText: "Generate Excel Report",
        html: "<p>Pulling dashboard data into an Excel report.</p> <p>This involved aggregating a lare amout of data and may take several minutes depending on the size of your project, reporting package, and reported values.</p><p>Please be patient.</p>",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch()
            .then((response) => response.blob())
            .then((data) => {
              let blobUrl = window.URL.createObjectURL(data);
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Download ready",
                html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" downoad="${filename}">Download</a>`,
              });
            })
            .catch((err) => {
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error generating your report.  Please contact support if the probem persists.",
              });
            });
        },
      },
    ]);
  };

  return (
    <ProjectPermission
      projectId={projectId}
      requiresAdmin={false}
      requiredActions={isRootRef ? ["READ_PROJECT_REPORT"] : []}
      showWarning={true}
      {...props}
    >
      {/*<h3>{isRootRef ? "Project" : "Reporting Package"} Dashboard - Overview</h3>*/}
      <div style={{ fontSize: "1.15rem" }}>
        <BreadCrumb />
      </div>

      <ReportingPeriodRangePicker
        filters={filters}
        setFilters={setFilters}
        onChange={({ fromDate, toDate }) =>
          setDateRange({
            fromDate: new Moment(fromDate),
            toDate: new Moment(toDate),
          })
        }
      />

      {/* Render the charts */}
      <BSBtnDropdown
        header="Dashboard menu"
        links={[
          {
            name: "Export Indicators to Excel",
            onClick: () =>
              fetchIndicatorExport({
                projectId,
                refId: isRootRef ? refId : "root",
                period: "project",
                cumulative: false,
                fileName: `indicators.csv`,
              }),
          },
          {
            name: "Export Indicators to Excel (cumulative)",
            onClick: () =>
              fetchIndicatorExport({
                projectId,
                refId: isRootRef ? refId : "root",
                period: "project",
                cumulative: true,
                fileName: `indicators.csv`,
              }),
          },
          { name: "Export Inputs to Excel", onClick: (e) => downloadExcel(e, "project_inputs", "project_inputs.csv") },
          { name: null },
        ]}
      />
      <div style={{ clear: "both" }} />

      <IndicatorWarning indicators={indicators} />

      <DashboardComponents
        projectId={projectId}
        refId={isRootRef ? "root" : refId}
        indicators={indicators}
        iconPath={null}
        filters={filters}
      />
    </ProjectPermission>
  );
}
