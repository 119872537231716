import React, { useState } from "react";
import Select from "react-dropdown-select";

import { SubNodes } from "./node";

import RowErrorBoundary from "./error";
import { useReportMetricValues } from "../utils/hooks";
import { useReportContext } from "./context";

/* Reduce a path down to a map of all descendants.  Values of "false" will be rendered but hidden, however true will be fully rendered*/
const getShowPathDict = (path) => {
  if (!path || !path.length) {
    return {};
  }
  let depth = path.length / 7;
  let endPoints = Array(depth)
    .fill(1)
    .map((x, y) => 7 * (y + 1));
  let paths = endPoints.reduce((acc, cur) => ({ ...acc, [path.substring(0, cur)]: false }), {});
  paths = { ...paths, [path]: true };
  return paths;
};

function NoDataWarningRow(props) {
  return (
    <tr>
      <td colSpan={100}>No child nodes or path information to search</td>
    </tr>
  );
}

function SearchNodesRow({ disableInputs, rootPath, indentLevel, nodes, show, setShow }) {
  let options = nodes
    .toList()
    .filter((node) => node && !node.getIn(["meta_json", "hidden"])) // Filter out nodes marked as "hidden"
    .map((node, i) => ({
      path: node.get("_path"),
      name: node.getIn(["metric", "name"]),
      key: i,
    }))
    .toJS();

  return (
    <tr>
      <td colSpan={100}>
        <div style={{ marginLeft: `${(indentLevel + 1) * 1.5}em`, marginRight: "5em" }}>
          <Select
            disabled={disableInputs}
            options={options}
            labelField={"name"}
            valueField={"path"}
            clearable={true}
            searchable={true}
            searchBy={"name"}
            clearOnSelect={true}
            sortBy={"path"}
            placeholder={"Search and select metrics to report against by typing part of metric Name or ID..."}
            onChange={(values) => {
              if (!values || !values[0]) {
                return null;
              }
              setShow({ ...show, ...getShowPathDict(values[0].path) });
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const getInitShow = (baseMetricValueMap, nodes) => {
  // Set any nodes with baseMetric in baseMetricIdsWithValues
  let initShow = {};
  for (let node of nodes) {
    if (!node) continue;
    let path = node.get("_path");
    if (baseMetricValueMap.has(node.getIn(["metric", "uid"]))) {
      initShow[path] = node.getIn(["meta_json", "hidden"]) !== true;
    }
  }
  console.log("GENERATED INIT SHOW MAP", initShow);
  return initShow;
};

export default function ChildSearchRow(props) {
  let { baseMetricValueMap } = useReportMetricValues();
  let { getNode, getDescendants } = useReportContext();
  let { path, indentLevel, hide, disableInputs } = props;

  if (hide) {
    return null;
  }

  let descendantNodes = getDescendants(getNode(path));
  let [show, setShow] = useState(getInitShow(baseMetricValueMap, descendantNodes));

  if (!descendantNodes?.size || !path) {
    return <NoDataWarningRow />;
  }

  return (
    <React.Fragment>
      <SearchNodesRow
        nodes={descendantNodes}
        disableInputs={disableInputs}
        indentLevel={indentLevel}
        rootPath={path}
        show={show}
        setShow={setShow}
      />
      <RowErrorBoundary>
        <SubNodes {...props} parentNoReport={false} sortKey="metric.name" showOnly={show} />
      </RowErrorBoundary>
    </React.Fragment>
  );
}
