import { Tooltip } from "react-tippy";
import Octicon from "react-octicon";
import React from "react";
import { RequiredStar } from "../../../forms/fields";

const Header = (props) => {
  let { field } = props;
  let header = field.get("header");
  let description = field.get("description");
  let required = field.get("required") || field.get("type") === "selection";
  const headerWidth = Math.max(150, header.length * 10);

  if (description) {
    return (
      <th style={{ minWidth: `${headerWidth}px` }} className="">
        {header} <RequiredStar required={required} />
        <Tooltip html={description} animateFill={false} className={"text-left"}>
          <Octicon name="info" />
        </Tooltip>
      </th>
    );
  }

  return (
    <th style={{ minWidth: `${headerWidth}px` }}>
      {header} <RequiredStar required={required} />
    </th>
  );
};

export const TableHeaders = (props) => {
  let { fields, readOnly } = props;

  let headers = fields.map((field, i) => <Header key={i} field={field} />);
  return (
    <tr>
      {readOnly ? null : (
        <td className="text-center">
          <Octicon name="gear" />
        </td>
      )}
      {headers}
    </tr>
  );
};
