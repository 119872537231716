import React from "react";
import Moment from "moment";
import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { downloadS3Object } from "./reportDocumentRow";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import ReportRowErrorBoundary from "./error";
import { confirmDelete } from "traec-react/utils/sweetalert";
import Im from "immutable";

export const deleteDocumentObject = ({ trackerId, commitId, docId, docObjectId, trigger }) => {
  confirmDelete({
    text: `This will delete the document object and all associated upload data.\n\n Are you sure you would like to proceed?`,
    onConfirm: () => {
      doFetch(`/api/tracker/${trackerId}/commit/${commitId}/document/${docId}/object/${docObjectId}/`, "DELETE").then(
        (r) => trigger()
      );
    },
  });
};

function DocumentHistoryRow(props) {
  let { indentLevel, currentDocObject, commit } = props;

  if (!currentDocObject) {
    return null;
  }

  let filename = currentDocObject.get("filename");
  let is_staging = commit ? commit.get("is_staging") || commit.getIn(["status", "name"]) === "Requires Revision" : null;

  return (
    <tr>
      <td>
        <p style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
          {Moment(currentDocObject.get("created")).format("Do MMM YY, h:mm:ss a")}
        </p>
      </td>
      <td colSpan={4}>
        <a
          style={{
            cursor: "pointer",
            color: "rgb(0, 123, 255)",
            textDecoration: "underline",
          }}
          onClick={(e) => {
            e.preventDefault();
            downloadS3Object({ ...props, currentDocObject });
          }}
        >
          {filename}
        </a>
      </td>
      <td>
        {is_staging ? (
          <BSBtnDropdown
            links={[
              {
                name: `Delete`,
                onClick: (e) => {
                  deleteDocumentObject({ ...props, docObjectId: currentDocObject.get("uid") });
                },
              },
            ]}
          />
        ) : null}
      </td>
    </tr>
  );
}

function NoRevisionsMessage() {
  return (
    <tr>
      <td colSpan={7} style={{ textAlign: "center" }}>
        <i>No Revisions of this File in this Report.</i>
      </td>
    </tr>
  );
}

export default function DocumentHistoryRows(props) {
  let { hide, trackerId, commitId, docId, currentDocObject } = props;
  let { data: docObjects, trigger } = useApi(
    "/api/tracker/{trackerId}/commit/{commitId}/document/{docId}/object/?thisCommitOnly=true",
    {
      trackerId,
      commitId,
      docId,
    }
  );

  if (hide) return null;

  let currentDocId = currentDocObject ? currentDocObject.get("uid") : null;
  let filteredAndSortedDocObjects = docObjects
    .filter((i) => i.get("uid") != currentDocId)
    .sortBy((i) => i.get("created"))
    .reverse();

  if (!filteredAndSortedDocObjects?.size) {
    return <NoRevisionsMessage />;
  }

  return filteredAndSortedDocObjects.map((obj, i) => (
    <ReportRowErrorBoundary key={i}>
      <DocumentHistoryRow {...props} currentDocObject={obj} trigger={trigger} />
    </ReportRowErrorBoundary>
  ));
}
