import React from "react";
import { Link } from "react-router-dom";
import Octicon from "react-octicon";


function MenuIcon({item}) {
  let name = item.get("octicon");
  if (!name) { return null }
  return <Octicon name={name} />;
}


export function MenuItem({item}) {
  if (!item?.get("label")) { return <hr /> }

  return (
    <li className="nav-item">
      <Link to={item.get("to")} className="nav-link">
        <span>
          <MenuIcon item={item}/> {item.get("label")}
        </span>
      </Link>
    </li>
  );
}
