import Im from "immutable";
import Moment from "moment";

const isNumber = (value) => {
  console.log("validating isNumber", value);
  try {
    let _value = +value;
    return typeof _value === "number" && isFinite(_value);
  } catch {
    return false;
  }
};

const isPostcode = (value) => {
  if (!(typeof value === "string")) {
    return false;
  }
  if (!value.includes(" ")) {
    return false;
  }
  let parts = value.split(" ");
  if (!(parts.length === 2)) {
    return false;
  }
  if (!(parts[0].length >= 2 && parts[0].length <= 4)) {
    return false;
  }
  return parts[1].length === 3;
};

const isDate = (value) => {
  let date = Moment(value, "DD/MM/YYYY");
  return !!(Moment.isMoment(date) && date.isValid());
};

const validatorMap = {
  string: {
    func: null,
    message: "Value must be a string",
  },
  number: {
    func: isNumber,
    message: "Value must be a finite number",
  },
  postcode: {
    func: isPostcode,
    message: "Value must be a postcode with format PP YYY where the prefix PP is between 2 and 4 characters",
  },
  freetext: {
    func: null,
    message: "Value must be a string",
  },
  selection: {
    func: (value) => value !== "" && value != null,
    message: "Value must be one of the choices available",
  },
  date: {
    func: isDate,
    message: "Value must be a date in the format dd/mm/yyyy",
  },
};

export const validateFields = (fields, values) => {
  let errors = Im.Map();
  for (let field of fields) {
    let header = field.get("header");
    let value = values.get(header);

    // Check if there is a value
    let required = field.get("required");
    if (required && !value) {
      errors = errors.set(header, "This field is required");
      continue;
    }

    let fieldType = field.get("type");
    let validator = validatorMap[fieldType];

    if (!validator || !validator.func) {
      continue;
    }

    if (!validator.func(value, field)) {
      errors = errors.set(header, validator.message);
    }
  }
  return errors;
};
