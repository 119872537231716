import React from "react";
import { projectPermissionFilter } from "./utils/permissions";
import { useFullIds } from "AppSrc/project/utils/hooks";
import { usePermission } from "AppSrc/utils/permissions";
import { NavItems } from "AppSrc/navBar";
import { useToken } from "storybook-dashboard/auth/token";
import Im from "immutable";

const sId = (_id) => _id?.substring(0, 8);

const getBaseUrls = (props) => {
  let { projectId, refId, isRootRef: _isRootRef } = props;
  let projectBase = `/project/${sId(projectId)}`;
  let wpackBase = refId ? `${projectBase}/wpack/${sId(refId)}` : projectBase;
  let isRootRef = (refId ? _isRootRef : true) || false;
  return { projectBase, wpackBase, isRootRef };
};

// To be set using Redux state
export function NavBarItems(props) {
  let { getClaims } = useToken();
  let { projectId, refId, isRootRef } = useFullIds();
  let { permissions } = usePermission({ projectId });

  let claims = getClaims();
  let links = isRootRef ? projectNavBarLinks({ projectId }) : reportPackageNavBarLinks({ projectId, refId, isRootRef });

  return (
    <NavItems
      items={Im.fromJS(projectPermissionFilter(links, permissions, claims))}
      user={claims}
      permission={permissions}
    />
  );
}

const reportPackageNavBarLinks = (props) => {
  let { isApportionmentProject } = props;
  let { wpackBase: base } = getBaseUrls(props);

  return [
    { label: "Dashboard", requiresAdmin: false, to: `${base}` },
    { label: "New report", requiresAdmin: false, to: `${base}/report` },
    { label: "Historic reports  ", requiresAdmin: false, to: `${base}/evals` },
    // {
    //   label: getTerm("Reporting Package", props),
    //   requiresAdmin: false,
    //   to: [
    //     {
    //       label: "Dashboard",
    //       to: base,
    //       octicon: "home",
    //       requiresAdmin: false,
    //       requiredActions: ["READ_PROJECT_REPORT"]
    //     },
    //     {
    //       label: "Report due",
    //       to: `${base}/report`,
    //       octicon: "list-ordered",
    //       requiresAdmin: false,
    //       requiredActions: ["UPDATE_TRACKER_REF_SCORE_VALUE"]
    //     },
    //     {
    //       label: "Reports Submitted",
    //       to: `${base}/evals`,
    //       octicon: "checklist",
    //       requiresAdmin: false,
    //       requiredActions: ["READ_TRACKER_REF_SCORE_VALUE"]
    //     }
    //   ]
    // },
    // {
    //   label: "Admin",
    //   requiresAdmin: true,
    //   to: [
    //     { label: "Metrics", to: `${base}/metrics`, octicon: "server", requiresAdmin: true },
    //     { label: "Conversion Factors", to: `${base}/conv`, octicon: "package", requiresAdmin: true },
    //     { label: "Indicators", to: `${base}/indicators`, octicon: "issue-closed", requiresAdmin: true },
    //     isApportionmentProject
    //       ? {
    //           label: "Apportionment",
    //           to: `${base}/apportionment`,
    //           octicon: "list-unordered",
    //           requiresAdmin: true
    //         }
    //       : null,
    //     { label: "Settings", to: `${base}/details`, octicon: "gear", requiresAdmin: true }
    //   ]
    // }
  ];
};

const projectNavBarLinks = (props) => {
  let { projectId } = props;
  let { projectBase: base } = getBaseUrls(props);
  return [
    {
      label: "Project",
      requiresAdmin: false,
      to: [
        {
          label: "Dashboard",
          to: base,
          octicon: "home",
          requiresAdmin: false,
          requiredActions: ["READ_PROJECT_REPORT"],
        },
        {
          label: "Members",
          to: `${base}/members`,
          octicon: "organization",
          requiresAdmin: false,
          requiredActions: ["READ_PROJECT_MEMBER"],
        },
      ],
    },
    {
      label: "Admin",
      requiresAdmin: true,
      to: [
        { label: "Metrics", to: `${base}/metrics`, octicon: "list-unordered", requiresAdmin: true },
        { label: "Conversion Factors", to: `${base}/conv`, octicon: "package", requiresAdmin: true },
        { label: "Indicators", to: `${base}/indicators`, octicon: "issue-closed", requiresAdmin: true },
        { label: "Settings", to: `${base}/details`, octicon: "gear", requiresAdmin: true },
        { label: null },
        {
          label: "Email Settings",
          to: `/project/${projectId}/email/settings`,
          octicon: "inbox",
          requiresAdmin: true,
        },
        { label: "Email Statistics", to: `/project/${projectId}/email/report`, octicon: "mail", requiresAdmin: true },
      ],
    },
  ];
};
