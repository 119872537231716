import React from "react";
import { Link } from "react-router-dom";
import { ProjectPermission } from "../utils/permissions";
import WorkPackageRow from "./row";
import { getTerm } from "AppSrc/tree/utils";
import { useFullIds } from "../utils/hooks";
//

export const counter = { row: 0 };

export default function WPTree(props) {
  let { projectId, company } = useFullIds();

  let companyName = company?.get("name");
  let companyId = company?.get("uid");

  let _companyId = companyId?.substring(0, 8);
  counter.row = 0;

  let rp_term = getTerm("Reporting Package", props);

  return (
    <ProjectPermission projectId={projectId} requiredActions={["READ_TRACKER_REF"]}>
      <div className="container-fluid m-0 p-0">
        <h5>{rp_term}s</h5>

        <Link to={`/company/${_companyId}`}>
          <p className={`m-0 p-0`}>
            <b>{companyName}</b>
          </p>
        </Link>

        <WorkPackageRow {...props} />
      </div>
    </ProjectPermission>
  );
}
