import React, { useState } from "react";
import MetricRowComponent from "./reportMetricRow";
import Im from "immutable";
import { useReportContext } from "./context";

import {
  MetricNameDescriptionCell,
  ReportValueInputCell,
  ReportCommentCell,
  ReportNoInputCheckbox,
  RowAdminPanel,
  isNotInFrequency,
} from "./reportMetricRow";
import RowErrorBoundary, { MiniErrorBoundary, CellSpanErrorBoundary } from "./error";

function SelectableMetricUnitCell({ path, selectionNodes, setCurrentScore, hide, disableInputs }) {
  let { getNode } = useReportContext();
  let score = getNode(path);

  if (hide) return null;

  if (disableInputs) {
    let currentNode = (selectionNodes || Im.Map())
      .toList()
      .filter((node) => node && !node.getIn(["meta_json", "hidden"]) && node.get("_path") == score.get("_path"))
      .first();
    return <td className="border-0">{currentNode?.getIn(["metric", "unit"])}</td>;
  }

  let options = (selectionNodes || Im.Map())
    .toList()
    .filter((node) => node && !node.getIn(["meta_json", "hidden"])) // Filter out nodes marked as "hidden"
    .map((node, i) => (
      <option key={i} value={node.get("_path")}>
        {node.getIn(["metric", "unit"])}
      </option>
    ));

  return (
    <td className="border-0">
      <select
        className="form-control form-control-sm m-0 p-0"
        value={score.get("_path")}
        onChange={(e) => {
          console.log("Selected value", e.target.value);
          setCurrentScore(selectionNodes.get(e.target.value));
        }}
      >
        {options}
      </select>
    </td>
  );
}

function RowInputCells(props) {
  let { isRequiredPeriodically, ValueInputComponent, valueColSpan, commentColSpan, hideUnits, hideNoReport } = props;

  ValueInputComponent = ValueInputComponent || ReportValueInputCell;

  if (isNotInFrequency(props)) {
    return <NotRequiredCells {...isRequiredPeriodically} />;
  }

  return (
    <CellSpanErrorBoundary colSpan={4}>
      <SelectableMetricUnitCell {...props} hide={hideUnits} />
      <ValueInputComponent {...props} colSpan={valueColSpan || 1} />
      <ReportCommentCell {...props} colSpan={commentColSpan || 1} />
      <ReportNoInputCheckbox {...props} hide={hideNoReport} />
    </CellSpanErrorBoundary>
  );
}

function SelectTwinInputComponent(props) {
  let { score, rowStyle } = props;

  // set the score to be the currently selected child
  let _props = props;

  let highlight = (score.get("period") || -1) > 0;

  return (
    <React.Fragment>
      <tr style={rowStyle} className={highlight ? "font-weight-bold" : ""}>
        <MetricNameDescriptionCell {..._props} />
        <RowInputCells
          {..._props}
          ValueInputComponent={ReportValueInputCell}
          valueColSpan={1}
          commentColSpan={1}
          hideUnits={false}
          hideNoReport={false}
        />
        <td className="border-0 d-flex justify-content-center">
          <MiniErrorBoundary>
            <RowAdminPanel {..._props} />
          </MiniErrorBoundary>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default function SelectTwinChildrenRow(props) {
  let { getNode, getDescendants, inputValueMap } = useReportContext();
  let { path } = props;

  let node = getNode(path);
  let childNodes = getDescendants(node);

  let selectionNodes = childNodes.reduce((a, c) => a.set(c.get("_path"), c), Im.Map());

  let initScore = selectionNodes
    .toList()
    .filter((i) => inputValueMap.has(i.getIn(["metric", "uid"])))
    .first();

  let [currentScore, setCurrentScore] = useState(initScore || selectionNodes.toList().first());

  let _path = currentScore.get("_path");

  return (
    <RowErrorBoundary>
      <MetricRowComponent
        {...props}
        InputComponent={SelectTwinInputComponent}
        setCurrentScore={setCurrentScore}
        path={_path}
        selectionNodes={selectionNodes}
      />
    </RowErrorBoundary>
  );
}
