import React from "react";
import Traec from "traec";
import Moment from "moment";
import { HTMLText } from "traec/utils/html";
import { CommentList } from "./index";
import { ErrorBoundary } from "traec-react/errors/handleError";
import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { useFullIds } from "../utils/hooks";
import { useProjectContext } from "../context";
import { useModal } from "storybook-dashboard/components/modal";

import { AddCommentModal } from "./index";

function ReplyButton(props) {
  let { fetches } = useProjectContext();
  let { setModal } = useModal();
  return (
    <ErrorBoundary>
      <button
        className="btn btn-sm btn-outline-secondary mt-0 mb-0 pt-0 pb-0 float-right"
        onClick={() => setModal(<AddCommentModal {...props} {...{ fetches }} />)}
      >
        <small>Reply</small>
      </button>
      <div style={{ clear: "both" }}></div>
    </ErrorBoundary>
  );
}

function CommentAttachments({ comment }) {
  let commentId = comment?.get("uid");
  let { data } = useApi(`/api/comment/${commentId}/`);
  let attachments = data?.get("attachments");

  if (!attachments || !attachments.size) {
    return null;
  }

  let attachmentLinks = attachments.map((i) => (
    <>
      <a href={i.get("url")}>{i.get("filename")}</a>{" "}
    </>
  ));

  return (
    <div>
      <span>
        <i>Attachments:</i>
      </span>{" "}
      {attachmentLinks}
    </div>
  );
}

export default function CommentItem(props) {
  let { path } = props;
  let { trackerId, commitId } = useFullIds();
  let { getNode, getChildren, fetches } = useProjectContext();

  let comment = getNode(path);
  let subComments = getChildren(comment);

  let dropdownLinks = [
    {
      name: "Edit",
      onClick: () => {
        console.log("Edit");
      },
    },
    {
      name: "Delete",
      onClick: () => {
        deleteComment();
      },
    },
  ];

  const deleteComment = () => {
    let { url, data, mutate } = fetches.nodes;
    doFetch(`${url}/${path}/`, "DELETE").then(() => mutate(data.filter((i) => !i.get("_path")?.startsWith(path))));
  };

  return (
    <ErrorBoundary>
      <div className="m-0 ml-3 mb-3 comment-item">
        {/*<BSBtnDropdown links={dropdownLinks} />*/}
        <CommentText comment={comment} {...{ trackerId, commitId }} path={path} />
        <CommentAttachments comment={comment} />
        <CommentCreator comment={comment} />
        <ReplyButton {...props} {...{ trackerId, commitId }} />
        <hr className="mt-2 mb-2" />
        <CommentList {...{ trackerId, commitId }} comments={subComments} />
      </div>
    </ErrorBoundary>
  );
}

function CommentCreator({ comment }) {
  let created = Moment(comment.get("created")).format("YYYY-MM-DD HH:MM");

  const creator =
    `${comment.getInPath("creator.first_name")} ${comment.getInPath("creator.last_name")}`.trim() ||
    comment.getInPath("creator.username");

  return (
    <small className="m-0 p-0">
      By <b>{creator}</b> on {created}
    </small>
  );
}

const CommentText = (props) => {
  let { comment, path } = props;

  let text = comment.get("text");
  let title = comment.get("title");

  if (!path) {
    return null;
  }

  return (
    <ErrorBoundary>
      <p className="mb-0">
        <b>{title}</b>
      </p>
      <HTMLText text={text} style={{}} />
    </ErrorBoundary>
  );
};
