import React from "react";
import { companyPermissionFilter } from "./permissions";
import { usePermission } from "AppSrc/utils/permissions";
import { useTerm } from "AppSrc/utils/terms";
import { NavItems } from "AppSrc/navBar";
import { useToken } from "storybook-dashboard/auth/token";
import { useFullIds } from "AppSrc/project/utils/hooks";
import Im from "immutable";

export function NavBarItems() {
  let { getClaims } = useToken();
  let { companyId } = useFullIds();
  let { permissions } = usePermission({ companyId });

  let claims = getClaims();

  let _companyId = companyId?.substring(0, 8);
  let base = `/company/${_companyId}`;

  let companyLabel = useTerm("Company");

  let items = [
    {
      label: companyLabel,
      requiresAdmin: false,
      to: [
        { label: "Dashboard", to: base, octicon: "home" },
        {
          label: "Members",
          to: `${base}/members`,
          octicon: "organization",
          requiresAdmin: false,
          requiredActions: ["READ_COMPANY_MEMBER"],
        },
      ],
    },
    {
      label: "Admin",
      requiresAdmin: true,
      to: [
        { label: "Indicators", to: `${base}/indicators`, octicon: "issue-closed", requiresAdmin: true },
        { label: "Settings", to: `${base}/details`, octicon: "gear", requiresAdmin: true },
        { label: null },
        { label: "Email Settings", to: `${base}/email/settings`, octicon: "inbox", requiresAdmin: true },
        { label: "Email Statistics", to: `${base}/email/report`, octicon: "mail", requiresAdmin: true },
      ],
    },
  ];
  return (
    <NavItems
      items={Im.fromJS(companyPermissionFilter(companyId, items, permissions, claims))}
      user={claims}
      permission={permissions}
    />
  );
}
