import React from "react";
import Octicon from "react-octicon";
import { Link } from "react-router-dom";


function DropdownIcon({item}) {
  let iconName = item.get("octicon");
  if (!iconName) {
    return null;
  }
  return <Octicon name={iconName} />;
}


function DropdownLabel({item, keyIndex}) {
  return (
    <span>
      {<DropdownIcon item={item}/>} {item?.get("label") || <hr key={keyIndex} />}
    </span>
  )
}

function DropdownMenuItem(props) {
  let {item, keyIndex, label} = props
  if (!item) { return null }

  let isLink = !!item.get("to")
  let Component = isLink ? Link : "a"
  let _props = isLink ? {to: item.get("to")} : {onClick: item.get("onClick")}

  if (!(label === undefined)) {
    return (
      <Component key={keyIndex} className="dropdown-item" {..._props}>
        <DropdownLabel {...props} />
      </Component>
    )
  } else {
    return <div key={keyIndex} className="dropdown-divider" />;
  } 
}


export function DropDownItem({ label, extraDropdownClass, items }) {
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {label}
      </a>
      <div className={`dropdown-menu dropdown-menu-right ${extraDropdownClass}`} aria-labelledby="navbarDropdown">
        {items?.map((item, i) => (<DropdownMenuItem key={i} label={label} item={item} keyIndex={i}/>))}
      </div>
    </li>
  );
}