import Im from "immutable";

export const companyPermissionFilter = (companyId, items, permissions) => {
  if (!permissions) {
    return null;
  }

  // Filter the items
  items = items.filter((i) => {
    if (i.requiresAdmin != null || i.requiredActions != null) {
      let requiresAdmin = i.requiresAdmin || false;
      let requiredActions = i.requiredActions || [];
      return companyPermissionCheck(companyId, requiresAdmin, requiredActions, permissions);
    }
    return true;
  });
  return items;
};

export const companyPermissionCheck = (companyId, requiresAdmin, requiredActions, permissions) => {
  // First check the user permissions for superuser status
  if (permissions.get("is_admin")) {
    return true;
  }

  // Handle an admin-type user first
  let isAdmin = permissions.get("is_admin");
  if (requiresAdmin) {
    return !!isAdmin;
  } else {
    // If admin is not required but the user
    // is an admin then they can do anything
    if (isAdmin) {
      return true;
    }
  }
  // Check against the list (actually Immutable.Set) of actions that the user has for this company
  let allowedActions = permissions.get("actions");
  let requiredActionSet = Im.Set(requiredActions);
  let intersectActions = requiredActionSet.intersect(allowedActions);
  return intersectActions.size === requiredActionSet.size;
};
