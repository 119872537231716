import React from "react";
import CompanyHealthcheckPage from "storybook-dashboard/dashboard/health/page";

const getName = (data) => data?.get("project_name") || data?.get("Reporting Package");

const getHealthFilters = () => {
  let host = location.hostname;
  if (host.startsWith("sizewellc") || host.startsWith("localhost")) {
    return [
      {
        title: "Report type",
        options: [
          {
            name: "All",
            filter: null,
          },
          {
            name: "Environment",
            filter: (data) => getName(data)?.includes("Environment Report"),
          },
          {
            name: "People/Workforce",
            filter: (data) => getName(data)?.includes("People/Workforce Report"),
          },
          {
            name: "Social Value",
            filter: (data) => getName(data)?.includes("Social Value"),
          },
        ],
      },
    ];
  }
  return [];
};

export default function CompanyHealthPage(props) {
  return <CompanyHealthcheckPage {...props} filters={getHealthFilters()} />;
}
