import React, { useContext } from "react";
import { useFullIds, useProjectPermissions } from "../utils/hooks";
import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { useSWRConfig } from "swr";
import { Spinner } from "traec-react/utils/entities";
import { useProjectContext } from "../context";
import Im from "immutable";

import { isAllRequiredSubmitted } from "./submit";
import { getChildMetricScores } from "./utils";
import { isRequiredOnFreq } from "./reportMetricRow";
import { listToMap } from "../metrics/node";

const ReportContext = React.createContext();

export const useReportContext = () => {
  return useContext(ReportContext);
};

const isANumber = (value) => !isNaN(parseFloat(value));

const getAncestorPaths = (path) => {
  let parts = path.match(/.{1,7}/g);
  return parts?.map((_, i) => parts.slice(0, -1 * (i + 1)).join(""))?.filter((i) => i) || [];
};

const allAreNA = (inputs) => inputs.every((i) => i?.getIn(["meta_json", "noReport"]));

const removeKey = (values, key) => values.map((i) => i.delete(key));

const valueObjectToPostData = (i) =>
  ["value", "comment", "meta_json", "metric"].reduce((a, c) => a.set(c, i.get(c)), Im.Map());

const putInputValues = async (url, inputValues) => {
  // Values that have been mutated will have an _path property
  // only save these to avoid writing all input values every time,
  // so instead save only values that have been mutated
  let valuesToSave = inputValues
    .filter((i) => i.has("_path")) // Getting only values that have been mutated
    .map((i) => valueObjectToPostData(i));

  // Do the actual fetch to the backend to save the values
  // TODO: check for errors
  let response = await doFetch(url, "POST", valuesToSave?.toJS());

  // This is not used if populateCache is set to false
  return { payload: removeKey(inputValues, "_path") };
};

export default function ReportContextWrapper({ children }) {
  let projectContext = useProjectContext();
  let { trackerId, commitId } = useFullIds();
  let { nodesByPath, getNode, conversionFactorMap } = projectContext;
  let { permissions, isLoading: isLoadingPermissions } = useProjectPermissions();

  // The data being submitted as the current report, at least one of these
  // changes with every input
  let { data, isLoading, url } = useApi("/api/tracker/{trackerId}/commit/{commitId}/value/", {
    trackerId,
    commitId,
  });
  const { mutate } = useSWRConfig();

  if (isLoading || isLoadingPermissions) {
    return (
      <Spinner title="Loading..." explanation={"Loading report..."} timedOutComment={"Loading report timed out"} />
    );
  }

  if (!data) {
    return (
      <Spinner
        title="Error loading"
        explanation={"Error loading report"}
        timedOutComment={"Error loading report timed out"}
      />
    );
  }

  // Get a mapping of input values by base metric ID
  let inputValueMap = data.reduce((a, i) => a.set(i.getIn(["metric", "metric", "uid"]), i), Im.Map());

  const updateInputMap = (data, _inputValueMap) => {
    let path = data.get("_path");
    let node = nodesByPath.get(path);
    let baseMetricId = node?.getIn(["metric", "uid"]);
    if (!data.has("metric")) {
      data = data.set("metric", node);
    }
    return baseMetricId ? _inputValueMap.mergeIn([baseMetricId], data) : _inputValueMap;
  };

  const getConversionFactor = (node, toUnit) => {
    let baseMetricId = node?.getIn(["metric", "uid"]);
    let fromUnit = node?.getIn(["metric", "unit"]);
    let cf = conversionFactorMap.get(Im.List([baseMetricId, toUnit]));
    // Use a conversion factor if it exists - otherwise if the from and to units are the same then use a value of 1
    return cf?.get("factor") || (fromUnit === toUnit ? 1 : null);
  };

  const updateInputsAndCalculateAncestors = (_inputValueMap, data) => {
    let path = data.get("_path");
    var inputValueMapWithNewInput = updateInputMap(data, _inputValueMap);
    var { updatedInputValueMap, values } = calculateAncestors(path, inputValueMapWithNewInput);
    return { updatedInputValueMap, values };
  };

  const saveNewValuesAndMutateLocalCache = (newInputValueMap) => {
    // Set the values in the cache and asynchronously save to the server
    let newValues = newInputValueMap.toList();
    mutate(url, () => putInputValues(url, newValues), {
      optimisticData: { payload: removeKey(newValues, "_path") },
      revalidate: false,
      ppoulateCache: false,
      rollbackOnError: false,
      throwOnError: true,
    });
  };

  // Handler for updating values when an input is set
  const setInputValue = (data) => {
    console.log("setInputValue", inputValueMap?.toJS());
    var { updatedInputValueMap } = updateInputsAndCalculateAncestors(inputValueMap, data);
    saveNewValuesAndMutateLocalCache(updatedInputValueMap);
  };

  const setMultipleInputValues = (dataList) => {
    var updatedInputValueMap = dataList.reduce(
      (_inputValueMap, data) => updateInputsAndCalculateAncestors(_inputValueMap, data).updatedInputValueMap,
      inputValueMap
    );
    saveNewValuesAndMutateLocalCache(updatedInputValueMap);
  };

  const updateSectionComplete = (categoryPath) => {
    console.log("Determining if section has all required fields complete", categoryPath);

    let node = getNode(categoryPath);
    let { commit, nodes, reportingPeriods } = projectContext;
    const sectionsComplete = commit.getIn(["meta_json", "_sectionsComplete"], Im.Map());
    const currentReportingPeriodId = commit?.get("reporting_period");
    const currentReportingPeriod = reportingPeriods?.find((rp) => rp.get("uid") === currentReportingPeriodId);
    let freqDetails = isRequiredOnFreq(node.get("meta_json"), currentReportingPeriod);
    if ((freqDetails || {}).dueThisReport === false) {
      return sectionsComplete.set(categoryPath, true);
    }

    // Get the metricscores that are under this category/issue
    let categoryMetricScores = getChildMetricScores(categoryPath, nodes, inputValueMap.toList());

    // Get the documents and documentstatuses that are under this category/issue
    // let categoryDocumentStatuses = getChildDocumentsWithStatus(categoryPath, nodes, documents, documentStatuses);
    let categoryDocumentStatuses = [];

    // If we have inputs then check all required is submitted
    const missing = isAllRequiredSubmitted(
      inputValueMap.toList(),
      categoryMetricScores,
      nodes,
      currentReportingPeriod,
      categoryDocumentStatuses
    );

    // log status
    let complete = !sectionsComplete.get(categoryPath);
    console.log(
      "Setting cateogory at path complete status",
      categoryPath,
      Im.isImmutable(complete) ? complete.toJS() : complete
    );

    if (complete && !missing.size) {
      sectionsComplete.set(categoryPath, complete);
      return true;
    } else return missing;
  };

  const validateSectionComplete = (categoryPath) => {
    let status = false;

    let errors = []; // inputErrors.get(categoryPath);
    if (errors && errors.size) {
      // First check there are no outstanding input errors
      console.warn("Section has errors - cannot mark as complete", categoryPath, errors.size, errors.toJS());
      let error_lines = errors
        .map((value, key, i) => `${key}`)
        .toList()
        .join("\n");
      alert(`Please correct input errors:\n${error_lines}`);
      status = errors;
    } else {
      // Check that all required fields are complete
      status = updateSectionComplete(categoryPath);
      if (!(status === true)) {
        console.warn("Section has required fields to complete - cannot mark as complete", categoryPath, status.toJS());
        alert(`Please complete the mandatory fields:\n${status.map((s) => s.get("metric").get("name")).join("\n")}`);
        return false;
      }
    }
    return status;
  };

  const setSectionComplete = (categoryPath, value) => {
    let { commit, setCommitMeta } = projectContext;
    console.log("Toggling section complete", categoryPath, value);
    if (value === true) {
      value = validateSectionComplete(categoryPath);
    }
    const sectionsComplete = commit.getIn(["meta_json", "_sectionsComplete"], Im.Map());
    setCommitMeta({
      _sectionsComplete: sectionsComplete.set(categoryPath, value).toJS(),
    });
  };

  const setSectionApproved = (categoryPath, value) => {
    let { commit, setCommitMeta } = projectContext;
    console.log("Toggling section approved", categoryPath, value);
    const sectionsApproved = commit.getIn(["meta_json", "_sectionsApproved"], Im.Map());
    setCommitMeta({
      _sectionsApproved: sectionsApproved.set(categoryPath, value).toJS(),
    });
  };

  // Calculate the value of a metric at a path
  const calculateAtPath = (path, _inputValueMap) => {
    let node = nodesByPath.get(path);
    if (!isCalculated(node)) return false;

    // The units of this node
    let toUnit = node.getIn(["metric", "unit"]);

    // Get an ordered array of the child nodes
    let childNodes = node
      .get("_children", Im.Set())
      .toList()
      .map((childPath) => getNode(childPath));

    // Get the raw child input objects
    let childInputs = childNodes
      .map((childNode) => childNode?.getIn(["metric", "uid"]))
      .map((baseMetricId) => _inputValueMap.get(baseMetricId));

    // Initialize mock input object that will store calculated data
    let data = Im.Map({ _path: path, value: null, metric: node });

    // Check if all children are marked as n/a
    if (allAreNA(childInputs)) {
      // If all children are marked as n/a then parent is n/a also
      data = data.setIn(["meta_json", "noReport"], true).set("value", null);
    } else {
      // Get the converion factors for each child
      let conversionFactors = childNodes.map((n, i) => getConversionFactor(n, toUnit));

      // Multiply by the conversion factors and sum
      let total = childInputs
        .map((input, i) => {
          let cf = conversionFactors.get(i);
          let value = input?.get("value");
          if (!isANumber(cf) || !isANumber(value)) return null;
          return parseFloat(value) * cf;
        })
        .reduce((a, c) => (isANumber(c) ? a + c : a), null);

      // Set calculated value
      data = data.setIn(["meta_json", "noReport"], false).set("value", total);
    }

    return {
      updatedInputValueMap: updateInputMap(data, _inputValueMap),
      data,
    };
  };

  // Calculate the value of ancestors until we hit one we shouldn't calculate
  const calculateAncestors = (path, _inputValueMap) => {
    let values = Im.List();
    for (let p of getAncestorPaths(path)) {
      let result = calculateAtPath(p, _inputValueMap);
      if (result === false) break;
      var { updatedInputValueMap: _inputValueMap, data } = result;
      values = values.push(data);
    }
    return {
      updatedInputValueMap: _inputValueMap,
      values,
    };
  };

  const isApprover = () => {
    let { commit, projectDisciplines } = projectContext;

    if (permissions?.get("is_admin")) return true;

    let projectBaseDisciplineMap = listToMap(projectDisciplines, "base_uid");
    let userDisciplineMap = listToMap(permissions.get("project_disciplines"), "base_uid");

    let disciplineId = commit.get("discipline");
    let projectDiscipline = projectBaseDisciplineMap.get(disciplineId);
    if (projectDiscipline) {
      let approverId = projectDiscipline.get("approver");
      let approverProjectDiscipline = projectDisciplines.get(approverId);
      if (approverProjectDiscipline) {
        return userDisciplineMap.has(approverId);
      }
    }
    return false;
  };

  const isResponsible = () => {
    let { commit } = projectContext;

    if (permissions?.get("is_admin")) return true;

    let userDisciplineMap = listToMap(permissions.get("project_disciplines"), "base_uid");
    let responsibleDisciplineId = commit.get("discipline");

    return userDisciplineMap.has(responsibleDisciplineId) || isApprover();
  };

  const isCalculated = (node) => {
    let { getChildren } = projectContext;
    // returns true if there are children and it isn't explicitly set as not
    // calculated
    if (node.hasIn(["meta_json", "calculated"])) {
      return node.getIn(["meta_json", "calculated"]);
    }

    // If there are children that are of an "input" type then calculate from those
    let inputTypeChildren = getChildren(node)?.filter((i) => i.get("_type") == "metricscore");

    return inputTypeChildren.size > 0;
  };

  return (
    <ReportContext.Provider
      value={{
        ...projectContext,
        inputValueMap,
        isCalculated,
        isResponsible,
        isApprover,
        setInputValue,
        setMultipleInputValues,
        setSectionComplete,
        setSectionApproved,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}
